<template>
  <b-tabs class="ctrl-tabs">
    <WareHouseAll />
    <b-tab title="Nearly sold out">
      <h2>lolo</h2>
    </b-tab>
    <b-tab title="Sold out">
      <h2>lolo</h2>
    </b-tab>
    <b-tab title="Inventory">
      <h2>lolo</h2>
    </b-tab>
  </b-tabs>
</template>

<script>
import WareHouseAll from '@/views/pages/warehouse/components/WareHouseAll.vue'
import {
  BTabs,
  BTab,
} from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
    WareHouseAll,
  },
}
</script>

<style lang="sass" scoped>

</style>

<template>
  <b-tab title="All">
    <div v-if="true">
      <div class="d-flex align-items mb-2">
        <div style="flex: 3;">Total: {{ items.length }} products</div>
        <!-- Sort -->
        <b-form-group
          label="Sorted by"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0 d-flex align-items-center justify-content-center"
          style="flex: 1;"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  -- none --
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Search"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <!-- <b-button :disabled="!filter" @click="searchFilter"> -->
              <b-button :disabled="!filter">
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        striped
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <!-- <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
          </b-form-checkbox>
        </template> -->

        <!-- <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Full Name : </strong>{{ row.item.full_name }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Post : </strong>{{ row.item.post }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Email : </strong>{{ row.item.email }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>City : </strong>{{ row.item.city }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Salary : </strong>{{ row.item.salary }}
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <strong>Age : </strong>{{ row.item.age }}
              </b-col>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template> -->

        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>
        <template #cell(name)="item">
          <b-link
            :to="`detail/${item.item.id}?product_id=${item.item.product_id}`"
          >
            {{ item.item.name }}
          </b-link>
        </template>
        <!-- <template #cell(name)="item">
          <div class="format-text">
            <p class="format-text__title">
              {{ item.item.name }}
            </p>
          </div>
        </template> -->
        <template #cell(price)="item">
          <span>$ {{ item.item.price }}</span>
        </template>

        <template #cell(is_active)="item">
          <ActiveStatus :value="item.item.is_active" />
        </template>
        <template #cell(more)="item">
          <div class="d-flex align-items-center">
            <b-link
              :to="{
                path: `detail/${item.item.id}?product_id=${item.item.product_id}`,
              }"
              class="text-secondary"
            >
              <feather-icon icon="EyeIcon" class="mr-50" />
            </b-link>
            <!-- <b-link
              :to="{ path: `edit/${item.item.id}` }"
              class="text-secondary mx-1"
            >
              <feather-icon icon="Edit3Icon" class="mr-50" />
            </b-link> -->
            <!-- <div class="text-danger" @click="handleDelete(item.item.id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
            </div> -->
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="10" sm="8" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            class="my-0"
          />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <no-data />
    </div>
  </b-tab>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-use-before-define */
import {
  BTab,
  BTable,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BPagination,
  BCol,
  BRow,
  BLink,
  BButton,
  // BFormCheckbox,
  // BButton, BCard, BRow, BCol,
  BAvatar,
} from 'bootstrap-vue'
import NoData from '@/views/components/nodata/index.vue'
import general from '@/mixins/index'
import ActiveStatus from '@/views/components/active-status/index.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'

export default {
  components: {
    BTab,
    BTable,
    NoData,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BPagination,
    BCol,
    BRow,
    BLink,
    BButton,
    // BButton,
    // BFormCheckbox,
    // BCard,
    // BRow,
    // BCol,
    BAvatar,
    ActiveStatus,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      fields: [
        { key: 'avatar', label: 'Avatar' },
        { key: 'name', label: 'Product name' },
        { key: 'sku', label: 'sku' },
        { key: 'price', label: 'Selling Price', sortable: true },
        { key: 'stock', label: 'Stock', sortable: true },
        { key: 'sold', label: 'Sold', sortable: true },
        { key: 'is_active', label: 'Status' },
        { key: 'updated_at', label: 'Lasted update', sortable: true },
        { key: 'more', label: 'More', sortable: false },
      ],
      items: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.loadList()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async loadList() {
      const templateId = JSON.parse(localStorage.getItem('templateID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_warehouses?template_id=${templateId}&count_page=100&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.perPage = res.data.data.count_page
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.items.length

            const array = []
            res.data.data.items.map(val => {
              if (!array.find(attName => attName.name === val.name)) {
                array.push(val)
              }
            })
            array.map(val => {
              const findData = res.data.data.items.filter(
                ({ name }) => name === val.name,
              )
              const item = {
                id: val.id,
                product_id: val.product_id,
                avatar: val.avatar,
                name: val.name,
                sku: val.sku,
                price: val.price,
                is_active: val.is_active,
                updated_at: '03/25/2021 1:58',
                // stock: 20,
                // sold: 5,
              }
              let sumStock = 0
              let sumSold = 0
              findData.map(y => {
                sumStock += y.stock
                sumSold += y.sold
                item.stock = sumStock
                item.sold = sumSold
              })
              this.items.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.format-text
  width: 200px

  &__title
    width: 100%
    text-overflow: ellipsis
    overflow: hidden
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    display: -webkit-box
    white-space: pre-wrap
</style>
